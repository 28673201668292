body {
    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth
}

.footer-dot-par {
    position: relative;
}

.footer-dot-par li {
    padding-left: 25px;
    position: relative;
}

.footer-dot-par li::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #1f86ef;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.cars_grid_parent .cars_parent{
    background-color: #e0e6fe;
}
.cars_grid_parent .cars_parent:nth-child(odd){
    background-color: #ffc9cb;
}
.cars_grid_parent .cars_parent:nth-child(even) .car_name{
    color: #241D92;
}
.circle,
.circle1 {
    border-radius: 50%;
    background-color: #28d230;
    top: 0;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}
.circle{
    background-color: #f443365c;
}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.tours3 {
    color: #676977;
}
.tours3 .square-flip {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -ms-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    background-size: cover;
    background-position: center center;
}
.tours3 .square-flip {
    min-height: 450px;
    margin-bottom: 30px;
}
.tours3 .square,
.tours3 .square2 {
    width: 100%;
    height: 100%;
}
.tours3 .square {
    background-size: cover;
    background-position: center center;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.tours3 .square .category {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;
    writing-mode: vertical-rl;
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    text-orientation: mixed;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Barlow', sans-serif;
    padding: 12px 6px;
    line-height: 1.5em;
    text-transform: uppercase;
    box-shadow: 0px 0px 35px 0px rgb(0 0 0 / 5%);
    background: #0f2454;
}
.tours3 .square .category a {
    color: #fff;
}
.tours3 .square .category:hover {
    color: #fff;
}
.tours3 .square h4 {
    color: #fff;
    font-size: 27px;
    text-align: left;
    margin-bottom: 10px;
    display: inline-flex;
}
.tours3 .square h6, .tours3 .square h6 a {
    position: relative;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 0px;
}
.tours3 .square-flip .square {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}
.tours3 .square-flip:hover .square {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.tours3 .square2 {
    background: #f4f5f8;
    background-size: cover;
    background-position: center center;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.tours3 .square2.bg-white {
    background: #fff;
}
.tours3 .square2:before {
    top: 25px;
    left: 15px;
    right: 15px;
    bottom: 25px;
}
.tours3 .square2 h4 {
    font-size: 27px;
    color: #0f2454;
    margin-bottom: 5px;
}
.tours3 .square2 h6 {
    position: relative;
    color: #2095AE;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Barlow', sans-serif;
    margin-bottom: 20px;
}
.tours3 .square2 p {
    font-size: 16px;
    color: #676977;
}
.tours3 .square2 .icon img {
    width: 60px;
    color: #676977;
    margin-bottom: 20px;
}
.tours3 .btn-line a {
    border-bottom: 1px solid #2095AE;
    color: #676977;
    padding-bottom: 5px;
}
.tours3 .btn-line a:hover {
    border-bottom: 1px solid #0f2454;
    color: #676977;
}
.tours3 .square-flip .square2 {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 1;
}
.tours3 .square-flip:hover .square2 {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.tours3 .square-container {
    padding: 40px;
    text-align: right;
    height: 450px;
    position: relative;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -webkit-transform: translateX(0px) scale(1);
    -ms-transform: translateX(0px) scale(1);
    transform: translateX(0px) scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}

.tours3 .square-flip:hover .square-container {
    -webkit-transform: translateX(-650px) scale(0.88);
    -ms-transform: translateX(-650px) scale(0.88);
    transform: translateX(-650px) scale(0.88);
}
.tours3 .square-container2 {
    padding: 30px;
    text-align: left;
    position: relative;
    top: 50%;
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
    -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 2;
}
.tours3 .square-flip:hover .square-container2 {
    -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
    transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
}
.tours3 .flip-overlay {
    display: block;
    background: #0f2454;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .2;
}
.car_outline{
    outline: 40px solid #fff;
}
.founder_style{
    font-family: 'Rock Salt', cursive;
}
.form_bg{
    z-index: 1;
}
.form_bg::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffc503;
    width: 100%;
    height: 100%;
    opacity: 0.95;
}
.form_shadow {
    background: #0597ff;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: background .3s ease-out,-webkit-transform .3s ease-out;
    transition: transform .3s ease-out,background .3s ease-out;
    transition: transform .3s ease-out,background .3s ease-out,-webkit-transform .3s ease-out;
}
.react-datepicker-wrapper{
    width: 100%;
}
.nav_link_parent li a{
    transition: 0.3s all ease;
}
.nav_link_parent li a:hover{
    background-color: #0a9313;
    padding: 10px 20px;
    color: #fff;
}
.typing_text span{
    line-height: 1;
}
.header_scrolled{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}
.dis_scroll{
    overflow: hidden;
}
.dis_scroll .header_scrolled{
    position: static;
}
.header-underline::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 20%;
    background-color: #ffc503;
}